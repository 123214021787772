import React, { Component } from 'react';
import { SlideProject, SlideEvent, SlideScreen } from '../../atoms';
import Slider from 'infinite-react-carousel';
import './style.css';

class SliderLoop extends Component {

    renderSliderLoop( slideLoop, type, thumbOnClick ) {
        var response = '';
        if ( slideLoop ) {
            response = slideLoop.map( function( single, index ) {
                switch( type ) {
                    case 'projects' : return( <SlideProject index={ index } key={ index } { ...single } /> ); 
                    case 'events'   : return( <SlideEvent index={ index } key={ index } { ...single } /> ); 
                    default         : return( <SlideScreen onClick={ thumbOnClick } key={ index } index={ index } { ...single } /> ); 
                }
            });
        }
        return response;
    }

    render() {

        var { slideLoop, type, thumbOnClick } = this.props;        

        return(
            <div className={ 'molecules slider-single slider-' + type } >
                <Slider rows={ 1 } slidesToShow={ 1 } arrows={ false }>
                    { this.renderSliderLoop( slideLoop, type, thumbOnClick ) }
                </Slider>
            </div>
        )
    }
}

export default SliderLoop;