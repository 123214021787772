import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setup } from '../../../config/';

import './style.css';

class SlideEvent extends Component {

    render() {


        var { linkTo, index, thumb, title, tag, month, day, addclass } = this.props;
        var imgRender = ( thumb ) ? <img src={ thumb.replace( setup.originWP, setup.originCDN ) } className={ 'ts ts-slide-out' } alt={ title } /> : false;
        var titClass = ( imgRender ) ? '' : ' noimg';
                
        return(
            <Link to={ linkTo } className={ 'atom slide-event ' +  addclass } key={ index }>
                <figure>
                    { imgRender }
                </figure>
                <div className={ 'copy-holder' + titClass }>
                    <span className={ 'tag' }>{ tag }</span>
                    <div className={ 'bottom-block' }>
                        <div className={ 'date-holder' }>
                            <span className={ 'month' }>{ month }</span>
                            <span className={ 'day' }>{ day }</span>
                        </div>
                        <h3>{ title }</h3>
                    </div>
                </div>
            </Link>
        )
    }
}

export default SlideEvent;