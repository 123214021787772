import React, { Component } from 'react';
import { ButtonHolder, SearchHolder } from '../../molecules';
import './style.css';

class TopSearchHeader extends Component {
        
    render() {
        
        var { backLink, backLabel, addIcon } = this.props;
        
        return(
            <header className={ 'organisms top-search-header ' + this.props.addClass } >
                <div className={ 'horizontal-flex' }>
                    <div className={ 'horizontal-flex-fix' }>
                        <ButtonHolder label={ backLabel } buttonClass={ 'inline' } linkDest={ backLink } addIcon={ addIcon } />
                    </div>
                    <div className={ 'horizontal-flex-flexible' }>
                        <SearchHolder { ...this.props } />
                    </div>
                </div>
            </header>
        )
    }
}

export default TopSearchHeader;