import React, { Component } from 'react';
import { setup } from '../../../config/';
import './style.css';

class DisplayImage extends Component {
    
    render() {

        var { source, title } = this.props;
        var renderTitle = ( title ) ? title : source;
        var fixSource = ( source ) ? source.replace( setup.originWP, setup.originCDN ) : source;
        
        return (
            <div className={ 'atoms display-image ' + this.props.addClass }>
                 <figure>
                    <img src={ fixSource } alt={ renderTitle } className={ 'ts ts-ease-out ts-slow' } />
                </figure>
            </div>

        )
    }
}

export default DisplayImage;