import React, { Component } from 'react';
import './style.css';

class DisplayHTML extends Component {

    render() {
        
        var { source } = this.props;

        return (
            <div className={ 'atoms display-html ' + this.props.addClass }>
                <iframe src={ source } title={ 'display-html-iframe' }></iframe>
            </div>

        )
    }
}

export default DisplayHTML;