const { 
    REACT_APP_MAX_IDLE_TIME,
    REACT_APP_ENVIROMENT_NAME, 
    REACT_APP_API_BASE_URL, 
    REACT_APP_API_PREFIX, 
    REACT_APP_API_REMOTE_TOKEN_URL,
    REACT_APP_API_DISPLAY_TOKEN_URL,
    REACT_APP_API_REMOTE_WS,
    REACT_APP_API_DISPLAY_WS,
    REACT_APP_UPLOADS_WP_PATH,  
    REACT_APP_UPLOADS_CDN_PATH
} = process.env;

export var setup = {
    'maxIdletime'       : REACT_APP_MAX_IDLE_TIME,
    'envirometName'     : REACT_APP_ENVIROMENT_NAME,
    'APIBaseUrl'        : REACT_APP_API_BASE_URL,
    'APIPrefix'         : REACT_APP_API_PREFIX,
    'WSRemoteTokenUrl'  : REACT_APP_API_REMOTE_TOKEN_URL,
    'WSDsiplayTokenUrl' : REACT_APP_API_DISPLAY_TOKEN_URL,
    'remoteSocket'      : REACT_APP_API_REMOTE_WS,
    'displaySocket'     : REACT_APP_API_DISPLAY_WS,
    'originWP'          : REACT_APP_UPLOADS_WP_PATH,
    'originCDN'         : REACT_APP_UPLOADS_CDN_PATH,
}