import React, { Component } from 'react';
import { LogoHeader } from '../../molecules';
import './style.css';

class TopSimpleHeader extends Component {

    render() {
        
        return(
            <header className={ 'organisms top-simple-header ' + this.props.addClass } >
                <LogoHeader />
            </header>
        )
    }
}

export default TopSimpleHeader;