import React, { Component } from 'react';
import { WaitingPage, HomePage, ProjectPage, EventPage, MashupPage } from '../pages';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getWebSocketTokenRemote, getSearchResults } from '../../API';
import { getRemoteSocket, getDisplaySocket } from '../../WS';
import { setup } from '../../config';
import './App.css';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            viewMaxIdleTime     : setup.maxIdletime,
            isSearchFetching    : false,
            isSearch            : false,
            searchQuery         : '',
            searchResult        : false,
            WSToken             : false,
            webSocket           : false,
            webSocketd          : false,
            WSReady             : false,
            WSError             : false,
            APIerror            : false,
            APIerrorInfo        : false,
            isScreenReady       : false,
            videoIsReady        : false,
            videoIsEnd          : false,
            videoIsBuffering    : true,
            mustReload          : false,
            mustReloadSC        : false,
            dspConnectInfo      : false
        };
        this.setIsSearchState       = this.setIsSearchState.bind( this );
        this.setIsNotSearchState    = this.setIsNotSearchState.bind( this );
        this.setSearchQuery         = this.setSearchQuery.bind( this );
        this.setAPIError            = this.setAPIError.bind( this );
        this.setIsScreenNotReady    = this.setIsScreenNotReady.bind( this );
        this.resetVideoStatus       = this.resetVideoStatus.bind( this );
        this.refreshPage            = this.refreshPage.bind( this );

    }
    
    refreshPage( mode ) {
        this.setState({ mustReload : mode });
    }
    
    refreshScreen( mode ) {
        console.log( 'refreshScreen', mode )
        this.setState({ mustReloadSC : mode });
    }
    
    refreshToken() {
        getWebSocketTokenRemote().then( ( response ) => {   
            this.setState({ 
                WSToken     : response.data.token,
                webSocket   : getRemoteSocket( this, response.data.token ),
                webSocketD  : getDisplaySocket( this, response.data.token )
            });
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }

    componentDidMount() {
        this.refreshToken(); 
        // setIpadBackHome();
    }

    setIsScreenNotReady() {
        this.setState({ isScreenReady : false })
    }

    setIsSearchState() {
        this.setState({ isSearch : true })
    }
    
    setIsNotSearchState() {
        this.setState({ 
            isSearch : false, 
            searchQuery : '', 
            searchResult : false 
        })
    }
    
    resetVideoStatus( mode ) {
        if ( mode === 'full' ) {
            this.setState({ 
                videoIsReady : false,
                videoIsEnd : false
            })
        } else {
            this.setState({ 
                videoIsEnd : false
            })            
        }
    }
    
    setSearchQuery( event ) {
        this.setState({ 
            isSearchFetching    : true,
            searchQuery         : event.target.value 
        });        
        getSearchResults( event.target.value ).then( ( response ) => {
            this.setState({ 
                searchResult        : response.data,
                isSearchFetching    : false
            });
        }).catch( ( error ) => { 
            this.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }

    setAPIError( error ) {
        this.setState({ APIerror : true, APIerrorInfo : error });
    }
    
    render_regular_mode() {

        const { searchQuery, webSocket, isSearchFetching, searchResult, WSReady, viewMaxIdleTime, isScreenReady, videoIsReady, videoIsEnd, videoIsBuffering, mustReload } = this.state;

        return (
            <div>
                <Switch>
                    
                    <Route path={ '/home' } render={ 
                        ({ match, history }) => 
                            <HomePage 
                                match               = { match } 
                                history             = { history } 
                                onFocusSearch       = { this.setIsSearchState } 
                                onBlurSearch        = { this.setIsNotSearchState } 
                                onSearchChange      = { this.setSearchQuery } 
                                searchQuery         = { searchQuery } 
                                isSearchFetching    = { isSearchFetching } 
                                searchResult        = { searchResult }
                                webSocket           = { webSocket }
                                WSReady             = { WSReady }
                                setAPIError         = { this.setAPIError }
                                maxIdleTime         = { viewMaxIdleTime }
                                mustReload          = { mustReload }
                            />
                        }
                    />
                    
                    <Route path={ '/project/:wpid' } render={ 
                        ({ match, history }) => 
                            <ProjectPage 
                                match               = { match } 
                                history             = { history } 
                                onFocusSearch       = { this.setIsSearchState } 
                                onBlurSearch        = { this.setIsNotSearchState } 
                                onSearchChange      = { this.setSearchQuery } 
                                searchQuery         = { searchQuery } 
                                isSearchFetching    = { isSearchFetching } 
                                searchResult        = { searchResult }
                                webSocket           = { webSocket }
                                WSReady             = { WSReady }
                                setAPIError         = { this.setAPIError }
                                maxIdleTime         = { viewMaxIdleTime }
                                isScreenReady       = { isScreenReady }
                                videoIsReady        = { videoIsReady }
                                videoIsEnd          = { videoIsEnd }
                                videoReset          = { this.resetVideoStatus }
                                setIsScreenNotReady = { this.setIsScreenNotReady }
                                videoIsBuffering    = { videoIsBuffering }
                                mustReload          = { mustReload }
                            /> 
                        } 
                    />
                    
                    <Route path={ '/event/:wpid' } render={ 
                        ({ match, history }) => 
                            <EventPage
                                match               = { match } 
                                history             = { history } 
                                onFocusSearch       = { this.setIsSearchState } 
                                onBlurSearch        = { this.setIsNotSearchState } 
                                onSearchChange      = { this.setSearchQuery } 
                                searchQuery         = { searchQuery } 
                                isSearchFetching    = { isSearchFetching } 
                                searchResult        = { searchResult }
                                webSocket           = { webSocket }
                                WSReady             = { WSReady }
                                setAPIError         = { this.setAPIError }
                                maxIdleTime         = { viewMaxIdleTime }
                                isScreenReady       = { isScreenReady }
                                videoIsReady        = { videoIsReady }
                                videoIsEnd          = { videoIsEnd }
                                videoReset          = { this.resetVideoStatus }
                                setIsScreenNotReady = { this.setIsScreenNotReady }
                                mustReload          = { mustReload }
                            />
                        } 
                    />

                    <Route path={ '/mashup/:mpos' } render={ 
                        ({ match, history }) => 
                            <MashupPage
                                match               = { match } 
                                history             = { history } 
                                onFocusSearch       = { this.setIsSearchState } 
                                onBlurSearch        = { this.setIsNotSearchState } 
                                onSearchChange      = { this.setSearchQuery } 
                                searchQuery         = { searchQuery } 
                                isSearchFetching    = { isSearchFetching } 
                                searchResult        = { searchResult }
                                webSocket           = { webSocket }
                                WSReady             = { WSReady }
                                setAPIError         = { this.setAPIError }
                                maxIdleTime         = { viewMaxIdleTime }
                                mustReload          = { mustReload }
                            />
                        }  
                    />

                    <Route path={ '/mashup/' } render={ 
                        ({ match, history }) => 
                            <MashupPage
                                match               = { match } 
                                history             = { history } 
                                onFocusSearch       = { this.setIsSearchState } 
                                onBlurSearch        = { this.setIsNotSearchState } 
                                onSearchChange      = { this.setSearchQuery } 
                                searchQuery         = { searchQuery } 
                                isSearchFetching    = { isSearchFetching } 
                                searchResult        = { searchResult }
                                webSocket           = { webSocket }
                                WSReady             = { WSReady }
                                setAPIError         = { this.setAPIError }
                                maxIdleTime         = { viewMaxIdleTime }
                                mustReload          = { mustReload }
                            />
                        } 
                    />

                    <Route path={ '/' } render={ 
                        ({ match, history }) => 
                            <WaitingPage
                                match           = { match } 
                                history         = { history } 
                                webSocket       = { webSocket }
                                WSReady         = { WSReady }
                                setAPIError     = { this.setAPIError }
                                onBlurSearch    = { this.setIsNotSearchState } 
                                mustReload      = { mustReload }
                                refreshPage     = { this.refreshPage }
                            />
                        } 
                    />

                </Switch>
            </div>
        )
    }
    
    resetAppConnection() {

    }

    render_connect_info() {
        return(
            <div className={ 'cnt-info' }>
                <ul>
                    <li><span>envirometName:</span> { setup.envirometName }</li>
                    <li><span>maxIdletime:</span> { setup.maxIdletime }</li>
                    <li><span>APIBaseUrl:</span> { setup.APIBaseUrl }</li>
                    <li><span>APIPrefix:</span> { setup.APIPrefix }</li>
                    <li><span>WSRemoteTokenUrl:</span> { setup.WSRemoteTokenUrl }</li>
                    <li><span>WSDsiplayTokenUrl:</span> { setup.WSDsiplayTokenUrl }</li>
                    <li><span>remoteSocket:</span> { setup.remoteSocket }</li>
                    <li><span>displaySocket:</span> { setup.displaySocket }</li>
                    <li><span>originWP:</span> { setup.originWP }</li>
                    <li><span>originCDN:</span> { setup.originCDN }</li>
                </ul>
            </div>
        )
    }
    
    setErrorPop( errCopy, addinfo ) {
        let addinfoCP = ( addinfo ) ? <p><em> { addinfo.toJSON().message } </em></p> : '';
        return(
            <div className={ 'error supersymetric' }>
                <h3>Error</h3>
                <p>{ errCopy }</p>
                { addinfoCP }
                <a href={ '/' } className={ 'atoms regular-button inline oversized' }>
                    <span>{ 'Reload' }</span>
                </a>
            </div>
        )
    }

    render() {
        
        const { isSearch, WSError, APIerror, APIerrorInfo, dspConnectInfo } = this.state;
        const appClass = ( isSearch ) ? ' search-mode' : ' regular-mode';

        let error = ( WSError ) ? this.setErrorPop( 'Connection to screens is lost', false ) : false;
        let errorAPI = ( APIerror ) ? this.setErrorPop( 'Connection to data is lost', APIerrorInfo ) : false;
        let errorClass = ( error || errorAPI ) ? ' ws-error' : ' no-error';
        let errorCont = ( errorAPI ) ? errorAPI : error;
        let content = ( errorCont ) ? errorCont : this.render_regular_mode();
        let connectInfo = ( dspConnectInfo ) ? this.render_connect_info() : '';

        return (
            <div className={ 'App ts ts-ease-in ts-slow grad-back' + appClass +  errorClass }>
                { connectInfo }
                <Router>
                    { content }
                </Router>
            </div>
        );
    }

}

export default App;