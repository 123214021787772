import React, { Component } from 'react';
import { RegularCopyBlock } from '../../atoms';
import './style.css';

class ProjectInfo extends Component {

    render() {

        return(
            <div className={ 'molecules project-info ' + this.props.addClass } >
                <RegularCopyBlock { ...this.props }/>
            </div>
        )
    }
}

export default ProjectInfo;