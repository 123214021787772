import React, { Component } from 'react';
import { RegularInput } from '../../atoms';
import './style.css';

class SearchHolder extends Component {

    render() {

        var { onBlurSearch, onFocusSearch, searchQuery, onSearchChange } = this.props;
        
        return(
            <div className={ 'molecules search-holder ' + this.props.addClass } >
                <div className={ 'inner' }>
                    <RegularInput placeholder={ 'Search' } type={ '' } value={ searchQuery } onBlur={ onBlurSearch } onFocus={ onFocusSearch } onChange={ onSearchChange } />
                </div>
            </div>
        )
    }
}

export default SearchHolder;