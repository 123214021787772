import React, { Component } from 'react';
import { RoundButtonHolder } from '../../molecules';
import './style.css';

class TouchControl extends Component {
    
    render() {

        return(
            <section className={ 'organism touch-control ' + this.props.addClass }>
                <RoundButtonHolder label={ 'Touch to Control' } buttonClass={ 'dashed-orange supersymetric' } linkDest={ '/home' } />
            </section>
        )
    }
}

export default TouchControl;