import React, { Component } from 'react';
// import { FullColorLogo } from '../../atoms';
import './style.css';

class EventDateInfo extends Component {

    render() {
        var { day, month } = this.props;
        return(
            <div className={ 'molecules event-date-info ' + this.props.addClass } >                    
                <div className={ 'date-holder' }>
                    <span className={ 'month' }>{ month }</span>
                    <span className={ 'day' }>{ day }</span>
                </div>
            </div>
        )
    }
}

export default EventDateInfo;