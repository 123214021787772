import React, { Component } from 'react';
import { Player } from 'video-react';
import '../../../../node_modules/video-react/dist/video-react.css';
import './style.css';
import { setup } from '../../../config/';
import { setVideoPlayBack } from '../../../WS/'

class DisplayVideo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isPlaying   : false, 
        }
        this.managePlayClick        = this.managePlayClick.bind( this );
        this.resetLocalVideostate   = this.resetLocalVideostate.bind( this );
    }

    managePlayClick() {
        this.props.resetIdleTimer();  
        this.props.videoReset('endVideo');
        this.setState({
            isPlaying : !this.state.isPlaying, 
        }, () => { setVideoPlayBack( this, this.state.isPlaying ); } );
    }
    
    resetLocalVideostate() {
        this.props.videoReset( 'full' );
        this.setState({
            isPlaying : false, 
        }, () => { setVideoPlayBack( this, false ); } );        
    }
    
    componentDidUpdate( prevProps, prevState ) {
        if ( prevProps.WSReady === false && this.props.WSReady ) {
            this.resetLocalVideostate();
        }

        if ( prevProps.source !== this.props.source ) {
            this.resetLocalVideostate();
            this.props.videoReset( 'full' );
        }
    }

    componentDidMount() {
        this.setState({
            isPlaying : false, 
        }, () => { this.props.videoReset( 'full' ) } );   
    }

    renderPoster( poster, videoIsReady, videoIsEnd, videoIsBuffering ) {

        var bufferingClass = ( videoIsBuffering ) ? ' is-buffering' : ' not-buffering';   
        var readyplayerClass = ( videoIsReady ) ? ' is-ready' : ' not-ready';        
        var playPauseClass = ( this.state.isPlaying && !videoIsEnd ) ? ' to-pause' : ' to-play';        
        
        var dspPoster = ( poster ) 
            ? <img className={ 'ts ts-slow ts-ease-out' } src={ poster.replace( setup.originWP, setup.originCDN )  } alt={ poster.replace( setup.originWP, setup.originCDN )  } /> 
            : ''; 
        return(
            <figure className={ 'poster' + playPauseClass + readyplayerClass + bufferingClass }>
                <span className={ 'supersymetric' }><span onClick={ this.managePlayClick } className={'icon-play' }></span></span>
                { dspPoster }
            </figure>
        )        
    }

    render() {
        var { poster, videoIsReady, videoIsEnd, videoIsBuffering } = this.props; 
        var RenderPoster = ( poster ) ? this.renderPoster( poster, videoIsReady, videoIsEnd, videoIsBuffering ) : '';
        return(
            <div className={'atoms display-video ts ts-slow ts-ease-out ' + this.props.addClass }>
                { RenderPoster }
            </div>
        )
    }
}

export default DisplayVideo;