import React, { Component } from 'react';
import { RoundButton } from '../../atoms';
import './style.css';

class RoundButtonHolder extends Component {
    
    render() {

        var { label, buttonClass, linkDest } = this.props;
        
        return(
            <div className={ 'molecules round-button-holder ' + this.props.addClass } >
                <RoundButton label={ label } linkDest={ linkDest } addClass={ buttonClass }/>
            </div>
        )
    }
}

export default RoundButtonHolder;