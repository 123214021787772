import React, { Component } from 'react';
import { SliderLoop } from '../../molecules';

import './style.css';

class LoopSlideHolder extends Component {

    render() {

        var { title, type, slideLoop } = this.props;
        var slideLoopRender = ( slideLoop ) ? <SliderLoop { ...this.props } /> : false;
        var renderTitle = ( title ) ? <h1>{ title }</h1> : '';
        
        return(
            <div className={ 'organisms inner-wrapper loop-slider-holder ' + this.props.addClass } data-type={ type }>
                { renderTitle }
                { slideLoopRender }
            </div>
        )
    }
}

export default LoopSlideHolder;