import React, { Component } from 'react';
// import { SlideProject, SlideEvent, SlideScreen } from '../../atoms';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { setup } from '../../../config/';
import './style.css';

class SliderLoop extends Component {
    
    constructor( props ) {
        super( props );
        this.slider = React.createRef();
    }
    
    renderSliderLoop( slideLoop, slideCol, onSlideClick, onDotsClick, feat ) {
        var response = '';
        if ( slideLoop ) {            
            response = slideLoop.map( function( single, index ) {
                var isFeatClass = ( feat === index ) ? ' is-feat' : ''; 
                return (
                    <div className={ 'slide-box' + isFeatClass } key={ index } >
                        <figure>
                            <img src={ single.thumb.replace( setup.originWP, setup.originCDN ) } alt={ 'slide-img-' + slideCol + '-' + index } />
                        </figure>
                        <p className={ 'ts ts-quick ts-ease-out' }>
                            <span className={ 'walls-trigger ' + isFeatClass } onClick={ () => { onSlideClick( index, slideCol ) } }></span>
                            <span className={ 'align-trigger ' + isFeatClass } onClick={ () => { onDotsClick( index ) } }></span>
                        </p>
                    </div>
                );
            });
        }
        return response;
    }

    render() {
        var { slideLoop, slideCol, onSlideChange, onSlideClick, onDotsClick, feat, initialSlide } = this.props;  
        this.slider.current && this.slider.current.slickGoTo( initialSlide )
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            centerMode: true,
            swipeToSlide: true,
            swipe: true,
            initialSlide: initialSlide,
            verticalSwiping: true,
            afterChange : ( index ) => { onSlideChange( index, slideCol ) },
        };
        
        return(
            <div className={ 'molecules vertical-slider-loop' } >
                <Slider ref={ this.slider } { ...settings }>
                    { this.renderSliderLoop( slideLoop, slideCol, onSlideClick, onDotsClick, feat ) }
                </Slider>
            </div>
        )
    }
}

export default SliderLoop;


