import React, { Component } from 'react';
// import { FullColorLogo } from '../../atoms';
import './style.css';

class EventLocationInfo extends Component {

    render() {

        var { time, place } = this.props;
        var divisor = ( time && place ) ? ' / ' : '';
        var contentRender = ( time || place ) ? <p><strong>{ time }{ divisor }{ place }</strong></p> : '';

        return(
            <div className={ 'molecules event-location-info ' + this.props.addClass } >
                { contentRender }
            </div>
        )
    }
}

export default EventLocationInfo;