import React, { Component } from 'react';
import { LoopSlideHolder } from '../../organisms';

import './style.css';

class SearchResultsHolder extends Component {
    
    renderResults( loop ) {
        return(
            <LoopSlideHolder addclass={ 'search-results' } slidesPerPage={ 4 } slideLoop={ loop } type={ 'screens' } />
        )    
    }
    
    renderNoQuery() {
        return(
            <div className={ 'noresponse inner-wrapper' }>
                <p>Please, insert search term</p>
            </div>
        )
    }

    renderNoResults( searchQuery ) {
        if ( searchQuery ) {
            return(
                <div className={ 'noresponse inner-wrapper' }>
                    <p>No matches for <span>{ searchQuery }</span></p>
                </div>
            );        
        } else {
            return false;
        }
    }

    render() {

        var { searchResult, isSearchFetching, searchQuery } = this.props;
        var contentResults = ( searchResult && searchResult.results ) ? this.renderResults( searchResult.results ) : this.renderNoResults( searchQuery );
        var contentNoQuery = ( !searchQuery ) ? this.renderNoQuery() : false; 
        var status = ( isSearchFetching ) ? 'fetching-search' : 'done-search';
                
        return(
            <div className={ 'organisms search-results-holder ' + this.props.addClass + ' ' + status }>
                <div className={ 'inner-wrapper responses' }>
                    { contentNoQuery }
                    { contentResults }
                </div>
            </div>
        )
    }
}

export default SearchResultsHolder;