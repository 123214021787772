import React, { Component } from 'react';
import { DisplayImage, DisplayVideo, DisplayHTML } from '../../atoms';
import './style.css';

class ScreenPreview extends Component {

    renderDisplayImage( props ) {
        return( <DisplayImage { ...props } /> )
    }

    renderDisplayVideo( props , webSocket, WSReady, resetIdleTimer, videoIsReady, videoIsEnd, videoReset, videoIsBuffering ) {
        return( 
            <DisplayVideo { ...props }
                webSocket={ webSocket } 
                resetIdleTimer={ resetIdleTimer } 
                videoIsReady={ videoIsReady }
                videoIsBuffering={ videoIsBuffering } 
                videoIsEnd={ videoIsEnd } 
                videoReset={ videoReset } 
                WSReady={ WSReady }
            /> 
        )
    }        
    
    renderDisplayHTML( props ) {
        return( <DisplayHTML { ...props } /> )
    }
    
    renderSelectedDisplayer( screen, webSocket, WSReady, resetIdleTimer, videoIsReady, videoIsEnd, videoReset, videoIsBuffering ) {
        var response;
        switch ( screen.mode ) {
            case 'video'    : response = this.renderDisplayVideo( screen, webSocket, WSReady, resetIdleTimer, videoIsReady, videoIsEnd, videoReset, videoIsBuffering ); break;
            case 'html'     : response = this.renderDisplayHTML( screen ); break;
            default         : response = this.renderDisplayImage( screen );
        } 
        return response;
    }
    
    getRandomSlidingCopy( isSliding ) {
        if ( isSliding ) {
            const slidingCopies = [
                'Loading your data'
            ];            
            return slidingCopies[Math.floor(Math.random()*slidingCopies.length)]
        } else {
            const doneCopies = [
                'sending to screens'
            ]; 
            return doneCopies[Math.floor(Math.random()*doneCopies.length)]           
        }
    }
    
    render() {

        var { screen, addInfoClass, hasAddInfo, webSocket, WSReady, isSliding, resetIdleTimer, videoIsReady, videoIsEnd, videoReset, videoIsBuffering } = this.props;
        var renderContent = this.renderSelectedDisplayer( screen, webSocket, WSReady, resetIdleTimer, videoIsReady, videoIsEnd, videoReset, videoIsBuffering );        
        var addInfoClassFn = ( hasAddInfo ) ? addInfoClass : 'unactive';
        var addSlidingClass = ( isSliding ) ? 'is-sliding' : 'no-sliding'
        var slidingContent = this.getRandomSlidingCopy( isSliding );
             
        return(
            <div className={ 'molecules screen-preview ' + this.props.addClass + ' ' + addSlidingClass }>
                <div className={ 'screen-load-trans supersymetric' }>
                    <p className={ 'ts ts-slower ts-delay ts-ease-out' }>{ slidingContent }</p>
                </div>
                <div className={ 'screen-content-trans ts ts-slower ts-ease-out' }>
                    { renderContent }
                </div>
                <span className={ 'add-info ts ts-slow ts-ease-out ' + addInfoClassFn }>
                    <p>Additional info displayed</p>
                </span>
            </div>
        )
    }
}

export default ScreenPreview;