import React, { Component } from 'react';
import { SlideProject, SlideEvent, SlideScreen } from '../../atoms';
import Slider from 'infinite-react-carousel';
import './style.css';

class SliderLoop extends Component {

    renderSliderLoop( slideLoop, type, thumbOnClick, display ) {
        var response = '';
        var prevClass = '';
        if ( slideLoop ) {
            response = slideLoop.map( function( single, index ) {
                var addclassFS = '';
                if ( prevClass !== single.addclass ) {
                    addclassFS = ' first-of-type ';
                    prevClass = single.addclass;
                }
                switch( type ) {
                    case 'projects' : return( <SlideProject index={ index } key={ index } display={ display } addclassFS={ addclassFS } { ...single } /> ); 
                    case 'events'   : return( <SlideEvent index={ index } key={ index } display={ display } addclassFS={ addclassFS } { ...single } /> ); 
                    default         : return( <SlideScreen onClick={ thumbOnClick } key={ index } index={ index } addclassFS={ addclassFS } display={ display } { ...single } /> ); 
                }
            });
        }
        return response;
    }

    render() {
                        
        var { slidesPerPage, slideLoop, type, thumbOnClick, display } = this.props;
        var loop = ( slideLoop ) ? this.renderSliderLoop( slideLoop, type, thumbOnClick, display ) : false;

        return(
            <div className={ 'molecules slider-loop slider-' + type } >
                <Slider rows={ 1 } slidesToShow={ slidesPerPage } arrows={ false }>
                    { loop }
                </Slider>
            </div>
        )
    }
}

export default SliderLoop;