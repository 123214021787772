import React, { Component } from 'react';
import { SliderSingle } from '../../molecules';

import './style.css';

class SingleSlideHolder extends Component {

    render() {

        var { title, type, slideLoop } = this.props;
        
        var content = ( slideLoop ) ? <SliderSingle { ...this.props } /> : <p></p>;
        
        return(
            <div className={ 'organisms single-slider-holder ' + this.props.addClass } data-type={ type }>
                <h1>{ title }</h1>
                { content }
            </div>
        )
    }
}

export default SingleSlideHolder;