import React, { Component } from 'react';
import './style.css';

class RegularCopyBlock extends Component {

    render() {

        var { title, description } = this.props;

        return(
            <div className={ 'atoms regular-copy-block ' + this.props.addClass }>
                <h1>{ title }</h1>
                <p>{ description }</p>
            </div>
        )
    }
}


export default RegularCopyBlock;