import React, { Component } from 'react';
import { FullColorLogo } from '../../atoms';
import './style.css';

class LogoHeader extends Component {

    render() {
        
        return(
            <div className={ 'molecules logo-header supersymetric ' + this.props.addClass } >
                <FullColorLogo />
            </div>
        )
    }
}

export default LogoHeader;