import React, { Component } from 'react';
import { ProjectInfo, ButtonHolder, EventLocationInfo, EventDateInfo } from '../../molecules';
import './style.css';

class EventInfoHolder extends Component {

    render() {

        var { onButtonClick, triggerStatus, hasAddInfo } = this.props;
        var triggerLabel = ( triggerStatus ) ? 'show less info' : 'show more info';
        var buttonClass = ( hasAddInfo ) ? '' : ' full-disabled';
        var triggerLabelDs = ( hasAddInfo ) ? triggerLabel : 'no more info';
        
        return(
            <section className={ 'organism event-info-holder grad-back ' + this.props.addClass }>
                <div className={ 'horizontal-flex' }>
                    <div className={ 'date-info-holder horizontal-flex-fix'}>
                        <EventDateInfo { ...this.props } /> 
                    </div>
                    <div className={ 'copy-holder horizontal-flex-flexible' }>
                        <ProjectInfo { ...this.props } />
                        <EventLocationInfo { ...this.props } />
                    </div> 
                    <div className={ 'trigger-holder horizontal-flex-fix' + buttonClass }>
                        <ButtonHolder label={ triggerLabelDs } onButtonClick={ onButtonClick } />
                    </div>
                </div>
            </section>
        )
    }
}

export default EventInfoHolder;