import React, { Component } from 'react';
import { TopSimpleHeader, TouchControl } from '../../organisms';
import { getHomeProjectsLoop } from './../../../API'
import { setDisplayKioskModeScreenSendMessage, forceReloadSendMessage } from '../../../WS/'
import './style.css';


class WaitingPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            load        : false,
            countDown   : 10,
        };
        this.getData = this.getData.bind( this );
        this.refreshPWASource = this.refreshPWASource.bind( this );
        this.renderVersion = this.renderVersion.bind( this );
    }
    
    getData() {
        getHomeProjectsLoop().then( ( response ) => {
            this.setState({ 
                load        : true,
            });
            this.props.onBlurSearch();
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }
    
    componentDidMount() {        
        this.getData();
        setTimeout( () => {
            this.getKioskModeScreens();         
        }, 2000 );        

        setTimeout( () => {
            if ( this.props.mustReload ) {
                setInterval( () => {
                    this.setState( { countDown : this.state.countDown - 1 } )
                }, 1000 );
            }
        }, 100 ); 

    }

    getKioskModeScreens() {
        if ( this.props.WSReady ) {
            setDisplayKioskModeScreenSendMessage( this );        
        }
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( prevProps.WSReady === false && this.props.WSReady ) {
            this.getKioskModeScreens();            
        }
        if ( prevProps.mustReload === false && this.props.mustReload ) {
            setInterval( () => {
                this.setState( { countDown : this.state.countDown - 1 } )
            }, 1000 );
        }
    }
    
    componentWillUnmount() {
        clearInterval( this.state.countDown ); 
    }

    refreshPWASource() {
        this.props.refreshPage( false );
        forceReloadSendMessage( this, false );  
        window.location.reload();
    }
    
    renderVersion() {
        var that = this;
        setTimeout( function() {
            that.refreshPWASource();
        }, 9800 );

        return(
            <div className={'new-version'}>
                <div className={'inner supersymetric'}>                
                    <p>New version available, reloading APP to get updates</p>
                    <p><em>Will reload in { this.state.countDown } seconds...</em></p>
                    <button onClick={ this.refreshPWASource }>Reload</button>
                </div>
            </div>
        );
    }
    
    render() {
        
        var loadClass = ( this.state.load ) ? 'loaded' : 'fetching'; 
        var version = ( this.props.mustReload ) ? this.renderVersion() : '';

        return(
            <div className={ 'page waiting-page ' + loadClass }>
                <div className={ 'vertical-flex' }>
                    { version }
                    <div className={ 'vertical-flex-fix header-holder ts ts-ease-out' }>
                        <TopSimpleHeader />
                    </div>
                    <div className={ 'vertical-flex-flexible touch-control-holder ts ts-ease-out' }>
                        <TouchControl />
                    </div>
                </div>
            </div>
        )
    }
}

export default WaitingPage;