import React, { Component } from 'react';
import { ScreenPreview } from '../../molecules';
import './style.css';

class ScreenPreviewHolder extends Component {
    
    render() {

        var { triggerStatus, display, hasAddInfo, tag, slideLoop, isSliding, webSocket, WSReady, resetIdleTimer, onLoadSlide, videoIsReady, videoIsEnd, videoReset, videoIsBuffering } = this.props;
        var addInfoClass = ( triggerStatus ) ? ' active' : ' unactive';
        var renderTag = ( tag ) ? <span className={ 'tag' }>{ tag }</span> : '';
        var displaySlide = ( slideLoop && slideLoop[ display ] ) ? slideLoop[ display ] : 0;

        return(
            <div className={ 'organisms screen-preview-holder ' + this.props.addClass } >
                <h2>Playing now</h2>
                <div className={ 'image-video' }>
                    { renderTag }
                    <ScreenPreview 
                        addInfoClass={ addInfoClass } 
                        screen={ displaySlide } 
                        hasAddInfo={ hasAddInfo } 
                        webSocket={ webSocket } 
                        isSliding={ isSliding } 
                        resetIdleTimer={ resetIdleTimer } 
                        onLoadSlide={ onLoadSlide }
                        videoIsReady={ videoIsReady }
                        videoIsEnd={ videoIsEnd }
                        videoIsBuffering={ videoIsBuffering }
                        videoReset={ videoReset }
                        WSReady={ WSReady }
                    /> 
                </div>
            </div>
        )
    }
}

export default ScreenPreviewHolder;