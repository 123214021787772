import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

class RoundButton extends Component {

    render() {

        var { label, linkDest } = this.props;

        return(
            <Link to={ linkDest } className={ 'atoms round-button ' + this.props.addClass }>
                <span>{ label }</span>
            </Link>
        )
    }
}


export default RoundButton;