import { get, API_PREFIX, REMOTE_TOKEN, DISPLAY_TOKEN } from './utils'

export function getHomeProjectsLoop() {
    const url = `${API_PREFIX}/get-project/all`;
    return get( url );
}

export function getSingleProjectInfo( wpid ) {
    const url = `${API_PREFIX}/get-project/${wpid}`;
    return get( url );
}

export function getHomeEventsLoop() {
    const url = `${API_PREFIX}/get-event/all`;
    return get( url );
}

export function getSingleEventInfo( wpid ) {
    const url = `${API_PREFIX}/get-event/${wpid}`;
    return get( url );
}

export function getMashUpThumnail() {
    const url = `${API_PREFIX}/get-mashup/thumb`;
    return get( url );
}

export function getMashUpCols() {
    const url = `${API_PREFIX}/get-mashup/ipad`;
    return get( url );
}

export function getSearchResults( query ) {
    const url = `${API_PREFIX}/get-search/results/?q=${query}`;        
    return get( url );
}

export function getWebSocketTokenRemote() {
    const url = REMOTE_TOKEN;
    return get( url );
}

export function getWebSocketTokenDisplay() {
    const url = DISPLAY_TOKEN;
    return get( url );
}