import React, { Component } from 'react';
import { VerticalSliderLoop } from '../../molecules';
import './style.css';

class MashupSlideHolder extends Component {

    renderMashupCol( props, key, feat, iniPos ) {

        return(
            <div className={ 'mashup-slide-col horizontal-flex-flexible' }>
                <VerticalSliderLoop slideCol={ key } slideLoop={ props.mashupSlide[ key ] } onSlideChange={ props.onSlideChange } onSlideClick={ props.onSlideClick }
                onDotsClick={ props.onDotsClick } feat={ feat } initialSlide={ iniPos } />
            </div>
        )
    }
    

    render() {

        var { mashupSlide, title, slide1Pos, slide2Pos, slide3Pos, slide4Pos, slide1Feat, slide2Feat, slide3Feat, slide4Feat } = this.props;
        var col1 = ( mashupSlide ) ? this.renderMashupCol( this.props, 0, slide1Feat, slide1Pos ) : ''; 
        var col2 = ( mashupSlide ) ? this.renderMashupCol( this.props, 1, slide2Feat, slide2Pos ) : ''; 
        var col3 = ( mashupSlide ) ? this.renderMashupCol( this.props, 2, slide3Feat, slide3Pos ) : ''; 
        var col4 = ( mashupSlide ) ? this.renderMashupCol( this.props, 3, slide4Feat, slide4Pos ) : ''; 
        return(
            <section className={ 'organism mashup-slide-holder ' + this.props.addClass }>
                <h1>{ title }</h1>
                <div className={ 'mashup-slide-wrapper horizontal-flex' }>
                    <div className={ 'shadow-box shadow-top' }></div>
                    <div className={ 'shadow-box shadow-bottom' }></div>
                    { col1 }
                    { col2 }
                    { col3 }
                    { col4 }
                </div>
            </section>
        )
    }
}

export default MashupSlideHolder;