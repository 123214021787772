import React, { Component } from 'react';
import { RegularButton } from '../../atoms';
import './style.css';

class ButtonHolder extends Component {

    render() {

        var { label, buttonClass, linkDest, addIcon, onButtonClick } = this.props;
        
        return(
            <div className={ 'molecules regular-button-holder ' + this.props.addClass } >
                <div className={ 'inner' }>
                    <RegularButton label={ label } addClass={ buttonClass } linkDest={ linkDest } addIcon={ addIcon } onclick={ onButtonClick } />
                </div>
            </div>
        )
    }
}

export default ButtonHolder;