import React, { Component } from 'react';
import * as images from '../../../assets/images';
import './style.css';

class LogoHeader extends Component {

    render() {
        
        return(
            <figure className={ 'atoms full-color-logo ' + this.props.addClass } >
                <img src={ images.fullLogoColor } alt={ 'Lawrenceville' } />
            </figure>
        )
    }
}

export default LogoHeader;