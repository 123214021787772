import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setup } from '../../../config/';
import './style.css';

class SlideProject extends Component {
    
    render() {

        var { linkTo, index, thumb, title, addclass } = this.props;
        var imgRender = ( thumb ) ? <img src={ thumb.replace( setup.originWP, setup.originCDN ) } className={ 'ts ts-slide-out' } alt={ title } /> : false;
        var titClass = ( imgRender ) ? '' : ' noimg';
        
        return(
            <Link to={ linkTo } className={ 'atom slide-project ' + addclass } key={ index }>
                <figure>
                    { imgRender }
                </figure>
                <div className={ 'copy-holder' + titClass }>
                    <h3>{ title }</h3>
                </div>
            </Link>
        )
    }
}

export default SlideProject;