import React, { Component } from 'react';
import './style.css';

class RegularInput extends Component {

    render() {

        var { placeholder, type, value, onBlur, onFocus, onChange } = this.props;

        return(
            <div className={ 'atoms regular-input ' + this.props.addClass }>
                <input type={ type } value={ value } placeholder={ placeholder } onBlur={ onBlur } onFocus={ onFocus } onChange={ onChange } className={ 'ts ts-slow ts-sease-out' } />
            </div>
        )
    }
}

export default RegularInput;