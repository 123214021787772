import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

class RegularButton extends Component {

    render_link_button( props ) {
        return(
            <Link to={ props.linkDest } className={ 'atoms regular-button ' + props.addClass  + ' ' + props.addIcon }>
                <span>{ props.label }</span>
            </Link>
        )
    }
    
    render_regular_button( props ) {
        return(
            <button onClick={ props.onclick } className={ 'atoms regular-button ' + props.addClass  + ' ' + props.addIcon }>
                <span>{ props.label }</span>
            </button>
        )
    }

    render() {

        var cnt = ( this.props.linkDest ) ? this.render_link_button( this.props ) : this.render_regular_button( this.props );

        return(
            <div>
                { cnt }
            </div>
        )
    }
}


export default RegularButton;